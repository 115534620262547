import React from 'react'


import { Outlet } from 'react-router-dom'
import { NavigationBar } from '../shared/NavigationBar'
import { useNavigation } from '../pages/auth/NavigationContext'
import { Container, Alert } from 'react-bootstrap'
export const Layout: React.FC = () => {
  const { errors } = useNavigation();
  return (
    <>
      <NavigationBar/>
      <Container>
        {errors.length > 0 && <Alert dismissible variant='danger'>{errors}</Alert>}
      </Container>
      <Outlet />
    </>
  )
}